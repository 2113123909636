import React, { createContext, useState, useEffect } from 'react';

const defaultValues = {
  checkout: {
    id: 0,
    channel_id: 1533294,
    coupons: [],
    line_items: { physical_items: [] },
    cart_amount: 0,
    redirect_urls: {
      cart_url: '',
      checkout_url: '',
      embedded_checkout_url: '',
    },
  },
  addToCart: () => {},
  removeFromCart: () => {},
  updateCartQuantity: () => {},

  bigCommerceClient: () => {},

  addDiscountToCheckout: () => {},
  removeDiscountFromCheckout: () => {},

  changeVatRelief: () => {},
};

export const StoreContext = createContext(defaultValues);

export const StoreProvider = ({ children }) => {
  const [checkout, setCheckout] = useState(defaultValues.checkout);

  const isBrowser = typeof window !== 'undefined';
  const localStorageCheckoutIdKey = `${process.env.GATSBY_BIGCOMMERCE_SHOP_NAME}:checkout-id`;
  const localStorageCustomerMessageKey = `${process.env.GATSBY_BIGCOMMERCE_SHOP_NAME}:customer-message`;

  useEffect(() => {
    initialiseCheckout();
  }, []);

  const getCart = async cartId => {
    try {
      const cart = await fetch('/.netlify/functions/checkoutApi', {
        method: 'POST',
        body: JSON.stringify({
          method: 'GET',
          endpoint:
            `carts/${cartId}?` +
            new URLSearchParams({
              include: 'redirect_urls',
            }),
        }),
      })
        .then(res => res.json())
        .then(responseData => responseData);

      return cart;
    } catch (e) {
      console.error(e);
    }
  };

  const addCheckoutConsignment = async cart => {
    try {
      await fetch('/.netlify/functions/checkoutApi', {
        method: 'POST',
        body: JSON.stringify({
          body: [
            {
              address: {
                first_name: '',
                last_name: '',
                email: '',
                company: '',
                address1: '',
                address2: '',
                city: '',
                state_or_province: '',
                state_or_province_code: '',
                country_code: 'GB',
                postal_code: '',
                phone: '',
                custom_fields: [],
              },
              line_items: cart.data.line_items.physical_items.map(
                ({ id, quantity }) => ({
                  item_id: id,
                  quantity: quantity,
                }),
              ),
            },
          ],
          endpoint: `checkouts/${cart.data.id}/consignments`,
        }),
      })
        .then(res => res.json())
        .then(responseData => responseData);
    } catch (e) {
      console.error(e);
    }
  };

  const initialiseCheckout = async () => {
    const cartId = isBrowser
      ? localStorage.getItem(localStorageCheckoutIdKey)
      : null;

    let newCheckout;

    if (cartId) {
      try {
        newCheckout = await getCart(cartId);

        if (!newCheckout.data) {
          localStorage.removeItem(localStorageCheckoutIdKey);
          localStorage.removeItem(localStorageCustomerMessageKey);
        } else {
          setCheckout(newCheckout.data);
        }
      } catch (e) {
        localStorage.removeItem(localStorageCheckoutIdKey);
        localStorage.removeItem(localStorageCustomerMessageKey);
        console.error(e);
      }
    }
  };

  const addToCart = async cartItem => {
    try {
      let cart;
      const cartId = isBrowser
        ? localStorage.getItem(localStorageCheckoutIdKey)
        : null;

      if (cartId) {
        cart = await fetch('/.netlify/functions/checkoutApi', {
          method: 'POST',
          body: JSON.stringify({
            body: cartItem,
            endpoint:
              `carts/${cartId}/items?` +
              new URLSearchParams({
                include: 'redirect_urls',
              }),
          }),
        })
          .then(res => res.json())
          .then(responseData => responseData);
      } else {
        cart = await fetch('/.netlify/functions/checkoutApi', {
          method: 'POST',
          body: JSON.stringify({
            body: {
              ...cartItem,
              channel_id: 1533294,
            },
            endpoint:
              'carts?' +
              new URLSearchParams({
                include: 'redirect_urls',
              }),
          }),
        })
          .then(res => res.json())
          .then(responseData => responseData);

        if (!cart.error) {
          // Recapture cart after checkout update
          cart = await getCart(cart.data.id);

          if (isBrowser && cart.data && cart.data.id) {
            localStorage.setItem(localStorageCheckoutIdKey, cart.data.id);
          }
        }
      }

      !cart.error && setCheckout(cart.data);

      return cart;
    } catch (e) {
      console.error(e);
    }
  };

  const removeFromCart = async cartItem => {
    try {
      const updatedCart = await fetch('/.netlify/functions/checkoutApi', {
        method: 'POST',
        body: JSON.stringify({
          method: 'DELETE',
          endpoint:
            `carts/${checkout.id}/items/${cartItem}?` +
            new URLSearchParams({
              include: 'redirect_urls',
            }),
        }),
      })
        .then(res => res.json())
        .then(responseData => responseData);

      if (!updatedCart.data) {
        // remove cart ID
        localStorage.removeItem(localStorageCheckoutIdKey);
        localStorage.removeItem(localStorageCustomerMessageKey);
        setCheckout(defaultValues.checkout);
      } else {
        setCheckout(updatedCart.data);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const updateCartQuantity = async (cartItemId, cartItem) => {
    try {
      const updatedCart = await fetch('/.netlify/functions/checkoutApi', {
        method: 'POST',
        body: JSON.stringify({
          method: 'PUT',
          body: cartItem,
          endpoint:
            `carts/${checkout.id}/items/${cartItemId}?` +
            new URLSearchParams({
              include: 'redirect_urls',
            }),
        }),
      })
        .then(res => res.json())
        .then(responseData => responseData);

      !updatedCart.error && setCheckout(updatedCart.data);

      return updatedCart;
    } catch (e) {
      console.error(e);
    }
  };

  const addDiscountToCheckout = async discountCode => {
    try {
      await fetch('/.netlify/functions/checkoutApi', {
        method: 'POST',
        body: JSON.stringify({
          method: 'POST',
          body: { coupon_code: discountCode },
          endpoint: `checkouts/${checkout.id}/coupons`,
        }),
      })
        .then(res => res.json())
        .then(responseData => responseData);

      const cart = await getCart(checkout.id);
      setCheckout(cart.data);
    } catch (e) {
      console.error(e);
    }
  };

  const removeDiscountFromCheckout = async discountCode => {
    try {
      await fetch('/.netlify/functions/checkoutApi', {
        method: 'POST',
        body: JSON.stringify({
          method: 'DELETE',
          endpoint: `checkouts/${checkout.id}/coupons/${discountCode}`,
        }),
      })
        .then(res => res.json())
        .then(responseData => responseData);

      const cart = await getCart(checkout.id);

      setCheckout(cart.data);
    } catch (e) {
      console.error(e);
    }
  };

  const bigCommerceClient = async graphql => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append(
      'Authorization',
      `Bearer ${process.env.GATSBY_BIGCOMMERCE_GRAPHQL_TOKEN}`,
    );

    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(graphql),
    };

    const response = await fetch(
      `https://store-${process.env.GATSBY_BIGCOMMERCE_API_STORE_HASH}.mybigcommerce.com/graphql`,
      requestOptions,
    )
      .then(response => response.json())
      .then(responseData => responseData)
      .catch(error => {
        return error;
      });

    return response;
  };

  const changeVatRelief = async (customerMessage = undefined) => {
    try {
      // Recreate current cart
      let lineItems = [];
      let updatedCart;

      // Need to add any variant ID's
      checkout.line_items.physical_items.map(item =>
        lineItems.push({
          quantity: item.quantity,
          product_id: item.product_id,
          variant_id: item.variant_id ? item.variant_id : undefined,
        }),
      );

      updatedCart = await fetch('/.netlify/functions/checkoutApi', {
        method: 'POST',
        body: JSON.stringify({
          body: {
            line_items: lineItems,
            channel_id: customerMessage ? 1533294 : 1,
          },
          endpoint:
            'carts?' +
            new URLSearchParams({
              include: 'redirect_urls',
            }),
        }),
      })
        .then(res => res.json())
        .then(responseData => responseData);

      if (!customerMessage) {
        localStorage.removeItem(localStorageCustomerMessageKey);
        await addCheckoutConsignment(updatedCart);
        updatedCart = await getCart(updatedCart.data.id);
      }

      setCheckout(updatedCart.data);

      // Set order notes for VAT relief data
      await fetch('/.netlify/functions/checkoutApi', {
        method: 'PUT',
        body: JSON.stringify({
          body: {
            customer_message: customerMessage,
          },
          endpoint: `checkouts/${updatedCart.data.id}`,
        }),
      })
        .then(res => res.json())
        .then(responseData => responseData);

      if (isBrowser && updatedCart.data && updatedCart.data.id) {
        localStorage.setItem(localStorageCheckoutIdKey, updatedCart.data.id);
      }

      return {
        updated: true,
        checkoutUrl: updatedCart.data.redirect_urls.checkout_url,
      };
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,

        checkout,
        addToCart,
        removeFromCart,
        updateCartQuantity,

        bigCommerceClient,

        addDiscountToCheckout,
        removeDiscountFromCheckout,

        changeVatRelief,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
